/* eslint-disable no-param-reassign */
/* eslin-disable no-cycle */
import axios from 'axios';
import { VITE_ENV_REVIEW } from '@/app/data/general_constants';
import store from '@/store/index';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { getInstance } from '@/auth';
import axiosRetry from 'axios-retry';
import { ZIGGU_API_URL } from '@/app/util/urls';
import _ from 'lodash-es';
import eventBus from '@/plugins/event_bus';

const api = axios.create({
  baseURL: ZIGGU_API_URL,
});

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = (2 ** retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

axiosRetry(
  api,
  {
    retries: 3,
    retryDelay,
  },
);

function validate_constraints(config) {
  // disable manipulative actions by employees during impersonation of customer
  const { current_is_impersonation } = store.getters;
  const { current_profile_true } = store.getters;
  if (current_is_impersonation && current_profile_true
      && current_profile_true.type === PROFILE_TYPES.EMPLOYEE && config.method !== 'get') {
    const error = new Error('Forbidden action during impersonate.');
    error.sentry_discard = true;
    throw error;
  }
}

// WARNING: should be kept in sync with Dropzone component as well (outside of axios' scope)
api.interceptors.request.use(
  async (config) => {
    validate_constraints(config);
    // create a new object off of config.params
    let config_params = { ...config.params };
    const access_token = await getInstance().getToken();
    // add authorization header with JWT authentication token
    config.headers.authorization = `Bearer ${access_token}`;
    // add referer header with full URL (request.referer only contains base URL due to SPA nature of app)
    config.headers['X-Referer-SPA'] = window.location.href;
    // append (relevant) meta params to request's parameters
    // (1) add 'subdomain' meta param to uniquely identify tenant
    let _subdomain = null;
    const parts = window.location.host.split('.');
    if (import.meta.env.VITE_ENV === VITE_ENV_REVIEW && !import.meta.env.IS_LOCAL) {
      if (parts.length > 3) [_subdomain] = parts;
    } else if (parts.length === 3) {
      [_subdomain] = parts;
    }
    if (_subdomain) {
      config_params = Object.assign(config_params || {}, { _subdomain });
    }

    // (2) add 'user_id' meta param to request in development mode
    if (import.meta.env.IS_LOCAL) {
      const _user_id = localStorage.getItem('_user_id');
      config_params = Object.assign(config_params || {}, { _user_id });
    }
    // (3) profile impersonation, add '_profile_id' and '_profile_type' meta params
    const _profile_id = localStorage.getItem('_profile_id');
    const _profile_type = localStorage.getItem('_profile_type');
    const profile = store.getters.current_profile;
    if (_profile_id && _profile_type) {
      // verify profile identical between vuex <-> localStorage, can go out of sync due to different scope:
      //  scope vuex: single tab
      //  scope localStorage: key-value pairs persist window and browser lifetimes and
      //    shared across every window or tab running at the same origin (regardless of path)
      if (profile && (profile.id !== _profile_id || profile.type !== _profile_type)) {
        // mismatch profile between vuex <-> localStorage, needs to trigger app reload to reset state
        //  e.g. multiple simultaneously opened tabs, switch profile in one tab, other tab(s) are out of sync
        window.location.reload(true);
      } else {
        config_params = Object.assign(config_params || {}, { _profile_id, _profile_type });
      }
    }
    config.params = config_params;
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for handling API responses and errors
api.interceptors.response.use(
  (response) => {
    // verify cache integrity by comparing "current" object between API (via custom HTTP header) and APP (via vuex)
    const timestamps_api = _.chain(response.headers['current-integrity'])
      .split(',')
      .map((timestamp) => [timestamp.split('=')[0], parseInt(timestamp.split('=')[1], 10)])
      .fromPairs()
      .value();
    const timestamps_app = _.chain(store.getters.current_integrity)
      .split(',')
      .map((timestamp) => [timestamp.split('=')[0], parseInt(timestamp.split('=')[1], 10)])
      .fromPairs()
      .value();
    if (timestamps_app['user.logout_at'] < timestamps_api['user.logout_at']) {
      eventBus.$emit('invalidateSession');
    }
    if (timestamps_app['client.updated_at'] < timestamps_api['client.updated_at']) {
      eventBus.$emit('fetchClient');
      eventBus.$emit('fetchFeatureToggles');
    }
    if (timestamps_app['profile.notified_at'] < timestamps_api['profile.notified_at']) {
      eventBus.$emit('fetchProfile');
      eventBus.$emit('fetchNotificationSubgroups');
    } else if (timestamps_app['profile.updated_at'] < timestamps_api['profile.updated_at']) {
      eventBus.$emit('fetchProfile');
      eventBus.$emit('fetchFeatureToggles');
    }
    return response;
  },
  (error) => {
    // let app handle unauthorized errors
    if (error.response !== undefined && error.response.status === 401) {
      eventBus.$emit('errors:401');
      throw error;
    // let app handle forbidden errors but do propagate error to Sentry (as this shouldn't happen)
    } else if (error.response !== undefined && error.response.status === 403) {
      eventBus.$emit('errors:403');
      throw error;
    // error which likely indicates access was revoked to a resource
    } else if (error.response !== undefined && error.response.status === 404) {
      eventBus.$emit('errors:404');
    // employee -> customer impersonation triggering a manipulative (forbidden) action
    } else if (error.message && error.message === 'Forbidden action during impersonate.') {
      eventBus.$emit('errors:418');
    // error without a response can e.g. be an unauthorized preflight OPTION request
    } else {
      // propagate other errors (to Sentry)
      throw error;
    }
  },
);

export default api;
